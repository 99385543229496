import * as React from "react";
import { Flex, Box, Heading, HStack, Text, Link } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";
import Navbar from "../components/Navbar";
import Helmet from "react-helmet";
import { IoLogoGithub, IoLogoLinkedin } from "react-icons/io5";
import { SiKofi } from "react-icons/si";

const Test = () => {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="The personal portfolio for Akaash Sinha"
        />
        <title>Akaash Sinha</title>
      </Helmet>
      <Navbar />
      <Flex justifyContent="center">
        <Box py={4}>
          <Heading size="3xl">Constantly Curious</Heading>
          <HStack align="center" spacing={3}>
            <Heading color="#BA0C2F" size="3xl">
              Double UGA
            </Heading>
            <Heading size="3xl">Alum.</Heading>
          </HStack>
        </Box>
      </Flex>
      <Flex justifyContent="center">
        <StaticImage
          src="../images/IMG_1863.jpg"
          layout="fixed"
          alt="Akaash at the Arch"
          placeholder="blurred"
          width={640}
          height={480}
        />
      </Flex>
      <Flex justifyContent="center" py={20}>
        <Box justifyContent="center">
          <Heading id="skills" textAlign="center" size="2xl">
            Skills
          </Heading>
          <br />
          <HStack spacing={10}>
            <Box>
              <Heading size="xl">Hard Skills</Heading>
              <Text>{"HTML & CSS"}</Text>
              <Text>Javascript (tutored others informally)</Text>
              <Text>Next.js</Text>
              <Text>Python</Text>
              <Text>Java (tutored others informally)</Text>
              <Text>SQL</Text>
            </Box>
            <Box>
              <Heading size="xl">Soft Skills</Heading>
              <Text>Listening</Text>
              <Text>Public Speaking</Text>
              <Text>Presenting</Text>
              <Text>Critical Thinking</Text>
            </Box>
            <Box>
              <Heading size="xl">What I Want to Learn</Heading>
              <Text>Becoming a Better Leader</Text>
              <Text>Machine Learning</Text>
              <Text>Game Development</Text>
              <Text>Graphic Design</Text>
            </Box>
          </HStack>
        </Box>
      </Flex>
      <Flex justifyContent="center" id="about">
        <Box>
          <Heading size="2xl" textAlign="center">
            About Me
          </Heading>
          <Box maxW={600} py={4}>
            <Flex justifyContent="center" py={10}>
              <StaticImage
                src={"../images/profile_picture.jpg"}
                placeholder="blurred"
                height={300}
                width={300}
                alt="Akaash Sinha Profile Picture"
              />
            </Flex>
            <Text textAlign="center" py={2}>
              Hi! I'm Akaash Sinha and welcome to my website. I was born and
              raised in Athens, Georgia. I finished my undergrad at the
              University of Georgia in 2020. I felt I still had a lot to learn,
              and finished up my Master of Business Technology 2022. I'm
              curently a Systems Analyst at the Board of Regents of the
              University System of Georgia
            </Text>
            <Text textAlign="center">
              My hobbies include watching{" "}
              <Link
                isExternal
                href="https://www.youtube.com/watch?v=04854XqcfCY"
                color="blue.500"
              >
                Braves baseball
              </Link>
              , reading, learning to play guitar, and learning in general.
            </Text>
          </Box>
        </Box>
      </Flex>
      <Flex id="contact" justifyContent="center" py={2}>
        <Box>
          <Heading textAlign="center">Contact</Heading>
          <HStack py={2} spacing={5}>
            <Link
              isExternal
              _hover={{ color: "blue.500" }}
              href="https://www.linkedin.com/in/akaashsinha/"
              aria-label="LinkedIn"
            >
              <IoLogoLinkedin size="75" />
            </Link>
            <Link
              isExternal
              _hover={{ color: "blue.800" }}
              href="https://github.com/akaashsinha"
              aria-label="GitHub"
            >
              <IoLogoGithub size="75" />
            </Link>
            <Link
              isExternal
              _hover={{ color: "red.500" }}
              href="https://ko-fi.com/akaashsinha"
              aria-label="Ko-Fi"
            >
              <SiKofi size="75" />
            </Link>
          </HStack>
        </Box>
      </Flex>
    </>
  );
};

export default Test;
